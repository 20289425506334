import Vue from 'vue'
import tabController from '@/tabController'

// Dummy observable to detect change accross components using this mixin
// The 'timestamp' property is updated every time we need the computed 'availableGlobalActions' to be recomputed
const lastUpdate = Vue.observable({ timestamp: 0 })

// Out of scope Map that contains active actions
const globalActionList = new Map()

export default {
  data () {
    return {
      globalActions: [],
      allActionsData: [
        Object.assign(tabController.accountContactsTab(), { callback: () => this.navigateCallback('/account-contacts/'), permissions: ['AccountContacts'] }),
        Object.assign(tabController.usersTab(), { callback: () => this.navigateCallback('/users/'), permissions: ['UserSettings'] }),
        Object.assign(tabController.agingScenarioTab(), { callback: () => this.navigateCallback('/aging-scenarios'), permissions: ['AgingBucketSettings'] }),
        Object.assign(tabController.topicsTab(), { callback: () => this.navigateCallback('/topics/') }),
        Object.assign(tabController.labelsTab(), { callback: () => this.navigateCallback('/labels/') }),
        Object.assign(tabController.labelGroupsTab(), { callback: () => this.navigateCallback('/label-groups/') }),
        Object.assign(tabController.workQueuesTab(), { callback: () => this.navigateCallback('/work-queues/'), permissions: ['WorkQueueSettings'] }),
        Object.assign(tabController.cameleonColumnItem({
          typeName: 't.Column',
          icon: 'i.Columns'
        }), { callback: () => this.navigateCallback('/columns'), permissions: ['GeneralSettings'] }),
        Object.assign(tabController.escalationProtocolTab(), { callback: () => this.navigateCallback('/escalation-protocols'), permissions: ['CollectionStrategySettings', 'AccountCanDebugProtocol'] }),
        Object.assign(tabController.processTab(), { callback: () => this.navigateCallback('/processes'), permissions: ['TaskEngines'] }),
        Object.assign(tabController.businessDivisionTab(), { callback: () => this.navigateCallback('/business-divisions'), permissions: ['CompanyStructureSettings'] }),
        Object.assign(tabController.providerTab(), { callback: () => this.navigateCallback('/providers'), permissions: ['GeneralSettings'] }),
        Object.assign(tabController.newImportTab(), { callback: () => this.$router.push('/new-import'), permissions: ['GeneralSettings', 'UpdateData'] })
      ]
    }
  },
  computed: {
    availableGlobalActions () {
      // Dummy use of 'lastUpdate.timestamp' to trick this computed to be recomputed when needed
      // eslint-disable-next-line no-unused-expressions
      lastUpdate.timestamp
      return globalActionList.size > 0
        ? [...globalActionList.values()]
        : this.allActions
    },
    allActions () {
      const allActions = []
      this.allActionsData.forEach(action => {
        if (this.$store.getters.currentUserHasAnyPermission(action.permissions ?? [])) {
          allActions.push(action)
        }
      })
      return allActions
    }
  },
  methods: {
    navigateCallback (r) {
      this.$router.push(r).catch(() => { })
    },
    // Add a list of global actions.
    // This list will be registered/unregistered when the component is activated/deactivated
    addGlobalActions (...actions) {
      actions.forEach(action => {
        if (this.$store.getters.currentUserHasEveryPermissions(action.permissions ?? [])) {
          this.globalActions.push(action)
        }
      })
      this.registerAllGlobalActions()
    },
    _globalActionRegister (action) {
      if (typeof action?.typeName !== 'string') {
        throw new Error(`Can't register this action, action.typeName must be a String, got "${typeof action?.typeName}"`)
      }
      if (typeof action?.icon !== 'string') {
        throw new Error(`Can't register this action, action.icon must be a String, got "${typeof action?.icon}"`)
      }
      if (typeof action?.callback !== 'function') {
        throw new Error(`Can't register this action, action.callback must be a Function, got "${typeof action?.callback}"`)
      }
      action.groupId = action.groupId ?? 100
      globalActionList.set(action.callback, action)
    },
    _globalActionUnregister (action) {
      globalActionList.delete(action?.callback)
    },
    registerAllGlobalActions () {
      this.$nextTick(() => {
        this.globalActions.forEach(action => this._globalActionRegister(action))
        this.updateAvailableGlobalActions()
      })
    },
    unregisterAllGlobalActions () {
      this.$nextTick(() => {
        this.globalActions.forEach(action => this._globalActionUnregister(action))
        this.updateAvailableGlobalActions()
      })
    },
    // Update the timestamp of our dummy observable.
    // This will force Vue to recompute 'availableGlobalActions' in every components using this mixin
    updateAvailableGlobalActions () {
      lastUpdate.timestamp = Date.now()
    }
  },
  mounted () {
    this.registerAllGlobalActions()
  },
  activated () {
    this.registerAllGlobalActions()
  },
  beforeDestroy () {
    this.unregisterAllGlobalActions()
  },
  deactivated () {
    this.unregisterAllGlobalActions()
  }
}
